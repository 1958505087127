<template>
  <div>
    <h3 style="text-align: left; padding-left: 20px">
      {{ title }}
    </h3>
    <md-toolbar>
      <slot name="actions" actionStyle="margin-right: 15px"></slot>
    </md-toolbar>
  </div>
</template>
<script>
export default {
  props: ["title"],
};
</script>
