<template>
  <md-button @click="$emit('click')" v-bind="$props">
    <template v-if="!disabled">
      <slot></slot>
    </template>
    <template v-else> Processing... </template>
  </md-button>
</template>
<script>
export default {
  props: {
    disabled: Boolean,
    className: String,
    click: Function,
  },
};
</script>
