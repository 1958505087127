<template>
  <div>
    <manage-user-header />
    <user-form />
  </div>
</template>
<script>
import ManageUserHeader from "./ManageUserHeader.vue";
import UserForm from "./UserForm.vue";
export default {
  components: { UserForm, ManageUserHeader },
};
</script>
