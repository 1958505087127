<template>
  <manage-page-header-layout
    :title="(manageUser.userId ? 'Edit' : 'Add') + ' User'"
  >
    <template v-slot:actions="{ actionStyle }">
      <default-button :style="actionStyle" @click="$router.back()"
        >Back</default-button
      >
      <default-button :style="actionStyle" @click="handleSave()"
        >Save</default-button
      >
      <default-button :style="actionStyle" @click="$router.back()"
        >Cancel</default-button
      >
    </template>
  </manage-page-header-layout>
</template>
<script>
import DefaultButton from "../../../components/controls/button/DefaultButton.vue";
import ManagePageHeaderLayout from "../../../components/layouts/manage-page-layout/ManagePageHeaderLayout.vue";
export default {
  components: { DefaultButton, ManagePageHeaderLayout },
  inject: ["manageUser", "handleSave"],
};
</script>
