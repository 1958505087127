<template>
  <manage-page-form-layout title="Account Information">
    <template v-slot:content>
      <ValidationListener
        @getIsValid="(isValid) => (userForm.isValid = isValid)"
      >
        <div class="md-layout">
          <div class="md-layout-item md-large-size-50 md-xsmall-size-100">
            <ValidationHandler
              :name="userForm.postData.firstName"
              rules="required"
              v-slot="{ error }"
            >
              <text-input
                label="First Name"
                v-model="userForm.postData.firstName"
                :required="true"
                :autofocus="true"
                :error="error"
              />
            </ValidationHandler>
          </div>
          <div class="md-layout-item md-large-size-50 md-xsmall-size-100">
            <ValidationHandler
              :name="userForm.postData.lastName"
              rules="required"
              v-slot="{ error }"
            >
              <text-input
                label="Last Name"
                v-model="userForm.postData.lastName"
                :required="true"
                :error="error"
              />
            </ValidationHandler>
          </div>
          <div class="md-layout-item md-large-size-50 md-xsmall-size-100">
            <ValidationHandler
              :name="userForm.postData.email"
              rules="required|email"
              v-slot="{ error }"
            >
              <text-input
                label="Email"
                v-model="userForm.postData.email"
                :required="true"
                :error="error"
              />
            </ValidationHandler>
          </div>
          <div class="md-layout-item md-large-size-50 md-xsmall-size-100">
            <ValidationHandler
              :name="userForm.postData.phone"
              rules="required|min:10|max:14"
              v-slot="{ error }"
            >
              <text-input
                label="Phone No."
                v-model="userForm.postData.phone"
                v-mask="'(###) ###-########'"
                :required="true"
                :error="error"
              />
            </ValidationHandler>
          </div>
          <div
            class="md-layout-item md-large-size-50 md-xsmall-size-100"
            v-if="!userForm.postData.id"
          >
            <ValidationHandler
              :name="userForm.postData.password"
              rules="required"
              v-slot="{ error }"
            >
              <text-input
                label="Password"
                type="password"
                v-model="userForm.postData.password"
                :required="true"
                :error="error"
              />
            </ValidationHandler>
          </div>
          <div class="md-layout-item md-large-size-50 md-xsmall-size-100">
            <ValidationHandler
              :name="userForm.postData.roleId"
              rules="required"
              v-slot="{ error }"
            >
              <select-input
                :name="Role"
                :place-holder="Role"
                :options="
                  master.roleList.map((x) => ({
                    value: x.id,
                    text: x.name,
                  }))
                "
                v-model="userForm.roleId"
                :required="true"
                :error="error"
              />
            </ValidationHandler>
          </div>
        </div>
      </ValidationListener>
    </template>
  </manage-page-form-layout>
</template>
<script>
import SelectInput from "../../../components/controls/SelectInput.vue";
import TextInput from "../../../components/controls/TextInput.vue";
import ManagePageFormLayout from "../../../components/layouts/manage-page-layout/ManagePageFormLayout.vue";
export default {
  components: { TextInput, SelectInput, ManagePageFormLayout },
  inject: ["userForm", "master"],
};
</script>
